import '../css/photoswipe.css';

import PhotoSwipeLightBox from './photoswipe-lightbox.esm.js'
import PhotoSwipe from './photoswipe.esm.js';

const PSOptions = {
    loop: true,
    padding: {
        top: 50,
        bottom: 50,
        left: 20,
        right: 20,
    },
    bgOpacity: 0.9,
    indexIndicatorSep: ' de ',
    pswpModule: PhotoSwipe,
    children: 'a',
    closeTitle: 'Cerrar',
    zoomTitle: 'Zoom',
};

const PSOptionsPorcelana = { ...PSOptions };
PSOptionsPorcelana.gallery = '#gallery-porcelana';
PSOptionsPorcelana.mainClass = 'pswp-porcelana';
const lightboxPorcelana = new PhotoSwipeLightBox(PSOptionsPorcelana);
lightboxPorcelana.init();

const PSOptionsPreservadas = { ...PSOptions };
PSOptionsPreservadas.gallery = '#gallery-preservadas';
PSOptionsPreservadas.mainClass = 'pswp-preservadas';
const lightboxPreservadas = new PhotoSwipeLightBox(PSOptionsPreservadas);
lightboxPreservadas.init();

const PSOptionsLaton = { ...PSOptions };
PSOptionsLaton.gallery = '#gallery-laton';
PSOptionsLaton.mainClass = 'pswp-laton';
const lightboxLaton = new PhotoSwipeLightBox(PSOptionsLaton);
lightboxLaton.init();

const PSOptionsCoronas = { ...PSOptions };
PSOptionsCoronas.gallery = '#gallery-coronas';
PSOptionsCoronas.mainClass = 'pswp-coronas';
const lightboxCoronas = new PhotoSwipeLightBox(PSOptionsCoronas);
lightboxCoronas.init();

const PSOptionsGemelos = { ...PSOptions };
PSOptionsGemelos.gallery = '#gallery-gemelos';
PSOptionsGemelos.mainClass = 'pswp-gemelos';
const lightboxGemelos = new PhotoSwipeLightBox(
    PSOptionsGemelos
);
lightboxGemelos.init();

const PSOptionsTiaras = { ...PSOptions };
PSOptionsTiaras.gallery = '#gallery-tiaras';
PSOptionsTiaras.mainClass = 'pswp-tiaras';
const lightboxTiaras = new PhotoSwipeLightBox(PSOptionsTiaras);
lightboxTiaras.init();

const PSOptionsDiademas = { ...PSOptions };
PSOptionsDiademas.gallery = '#gallery-diademas';
PSOptionsDiademas.mainClass = 'pswp-diademas';
const lightboxDiademas = new PhotoSwipeLightBox(PSOptionsDiademas);
lightboxDiademas.init();
